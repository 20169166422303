var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-grid',{attrs:{"w":"100%","h":{ base: 'calc(130% - 20px)', lg: 'calc(100% - 70px)' },"template-columns":{
    base: '1fr',
    lg: '100% 1fr',
    md: '1fr',
    sm: '1fr',
    xs: '1fr',
  },"gap":"8"}},[_c('c-stack',{attrs:{"pb":"3","px":"2","spacing":8,"maxHeight":"100%"}},[_c('NewPosts',{attrs:{"edited-post":_vm.postEdited,"open-modal":_vm.openPostModal},on:{"postAdded":_vm.onPostAdded}}),(!_vm.isFetchingPosts && !_vm.posts.length)?_c('c-text',{attrs:{"fontStyle":"italic"}},[_vm._v(" You have no posts on your timeline ")]):_vm._e(),_vm._l((_vm.posts),function(post,index){return [_c('c-box',{key:post.id},[_c('PostCard',{attrs:{"is-edited-post":(_vm.passStatus.id === post.id) && _vm.passStatus.isEdited,"post":post},on:{"post-deleted":function($event){return _vm.removeDeletedPost(index)},"togglePostSave":_vm.onPostSaveToggle,"selectPostAttachment":function($event){_vm.selectedPost = $event;
            _vm.isImagePreviewOpen = true;},"deletePost":function($event){return _vm.onDeletePost(post)}}})],1)]}),_c('infinite-loading',{on:{"infinite":_vm.getMorePosts}},[_c('span',{attrs:{"slot":"no-more"},slot:"no-more"},[_c('div',{staticStyle:{"border":"1px solid rgba(255, 69, 0, 0.45)"}},[_vm._v(" No more posts ")])]),_c('span',{attrs:{"slot":"no-results"},slot:"no-results"},[_c('div',{staticStyle:{"border":"1px solid rgba(255, 69, 0, 0.45)"}},[_vm._v(" No posts found ")])])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }